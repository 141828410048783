<template>
  <div>
    home
  </div>
</template>

<script>
export default {
  mounted() {
    // Redirect to appointments since ths view is not ready yet
    this.$router.push({ name: 'Appointment' });
  },
};
</script>

<style>

</style>
